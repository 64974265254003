import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Precision AutoWorks
			</title>
			<meta name={"description"} content={"Ласкаво просимо до нового місця, куди ви можете звернутися з будь-якими автомобільними потребами. "} />
			<meta property={"og:title"} content={"Precision AutoWorks"} />
			<meta property={"og:description"} content={"Ласкаво просимо до нового місця, куди ви можете звернутися з будь-якими автомобільними потребами. "} />
			<meta property={"og:image"} content={"https://sparkoluxprime.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://sparkoluxprime.com/img/423683224.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://sparkoluxprime.com/img/423683224.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://sparkoluxprime.com/img/423683224.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://sparkoluxprime.com/img/423683224.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://sparkoluxprime.com/img/423683224.png"} />
			<meta name={"msapplication-TileImage"} content={"https://sparkoluxprime.com/img/423683224.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-green" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--headline2"
							color="--dark"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Дізнайтеся про наші послуги
						</Text>
						<Text as="h2" margin="12px 0" font="--lead" md-font="--headline3">
						У Precision AutoWorks ми прагнемо продовжити термін служби вашого автомобіля, пропонуючи комплексні, ретельні послуги. Наші послуги спрямовані не лише на ремонт, але й на оптимізацію продуктивності та безпеки вашого автомобіля. Від регулярного технічного обслуговування до комплексної діагностики - кожна послуга, яку ми надаємо, виконується з однаковим рівнем точності та ретельності.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://sparkoluxprime.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://sparkoluxprime.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 38px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші досвідчені механіки
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Основою Precision AutoWorks є наша команда експертів-механіків. Кожен механік має сертифікат ASE, привносячи в наш сервісний центр величезний досвід і спеціалізовані знання. Ми забезпечуємо постійне навчання та розвиток, щоб залишатися на передовій автомобільних технологій та методів. Наші механіки - це не просто технічні фахівці, вони є ентузіастами та прихильниками автомобільної досконалості.
					<br />
					<br />
					Регулярне технічне обслуговування: Регулярне обслуговування для забезпечення безперебійної роботи вашого автомобіля.
					<br />
					<br />
Заміна оливи: Високоякісна заміна оливи та фільтрів для підвищення продуктивності двигуна.
					<br />
					<br />
Ротація шин: Подовжить термін служби шин і підвищить безпеку автомобіля.
					<br />
					<br />
Перевірка гальм: Комплексна перевірка, щоб переконатися, що ваші гальма чутливі та надійні.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					<Strong>
					Розширена діагностика -
					</Strong>
					{" "}Використання найсучаснішого обладнання для виявлення проблем та оптимізації роботи автомобіля.
					<br />
					<br />
					<Strong>
					Діагностика двигуна -
					</Strong>
					{" "}Ретельний аналіз для виявлення та усунення специфічних для двигуна проблем.
					<br />
					<br />
					<Strong>
					Випробування електричних систем -
					</Strong>
					{" "}Експертна оцінка електричних компонентів вашого автомобіля.
					<br />
					<br />
					<Strong>
					Ремонтні послуги -
					</Strong>
					{" "}Від незначних налаштувань до капітального ремонту - ми виконуємо все з високою точністю.
				</Text>
				<Link
					href="/contact"
					padding="12px 40px 12px 40px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					target="_blank"
					align-self="center"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://sparkoluxprime.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});